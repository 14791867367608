export const SIZE = {
  mobile: "375",
  tablet: "768px",
  laptop: "1024px",
  desktop: "1920",
}

export const DEVICES = {
  mobile: `(min-width: ${SIZE.mobile})`,
  tablet: `(min-width: ${SIZE.tablet})`,
  laptop: `(min-width: ${SIZE.laptop})`,
  desktop: `(min-width: ${SIZE.desktop})`,
}
