export const lightTheme = {
  body: "#FFFFFF",
  text: "#363537",
  primaryColor: "#F1B929",
  secondaryColor: "#FF9900",
}

export const darkTheme = {
  body: "#121721",
  text: " #6e8098;",
  primaryColor: "#F1B929",
  secondaryColor: "#FF9900",
}
